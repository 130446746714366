import React from 'react';

class Form extends React.Component {

    render() {
        return (
            <div>
                <form onSubmit={this.envioFormulario}>
                    <label>Id..:</label><br />
                    <input type="date" placeholder="Ingrese id" id="fecha" name="fecha" required /><br />
                    <label>Nombre:</label><br />
                    <input type="text" placeholder="Ingrese el nombre" id="nombre" name="nombre" required />
                    <br />
                    <button type="submit">Enviar</button>
                </form>
            </div>
        );
    }
    envioFormulario = event => {
        console.log("Formulario enviado:\nid:" + document.getElementById("nombre").value + "\nNombre:" + document.getElementById("fecha").value);
        event.preventDefault();
        this.props.contactoNuevo(
            document.getElementById("fecha").value,
            document.getElementById("nombre").value
        );
        document.getElementById("fecha").value = "";
        document.getElementById("nombre").value = "";
    }

}

export default Form;