import React from 'react';
import Form from './Form';
import './App.css'
class App extends React.Component{
    state = {
        contactos: [
            { id: 1, cumple: "24-09-1973", nombre: "César", regalo: false },
            { id: 2, cumple: "03-06-1968", nombre: "Eva", regalo: false },
            { id: 3, cumple: "13-06-2010", nombre: "Leo", regalo: false },
            { id: 4, cumple: "15-10-2001", nombre: "Maria", regalo: false }
        ]
    }
    render() {
        return (
            <div>
                Esta es mi lista de cumples:
                <dl>
                    {this.state.contactos.map(i =>
                        <div key={i.id}>
                            <dt>(Id: {i.id})</dt>
                            <dd>{i.nombre}:</dd>
                            <dd>{i.cumple}</dd>
                            <dd>
                                <button onClick={this.eliminarContacto.bind(this, i.id)} className="eliminar">Eliminar</button>
                                <button onClick={this.modificarContacto.bind(this, i.id, i.regalo)} className="modificar">{ i.regalo ? 'Voy' : 'No voy'}</button>
                            </dd>
                        </div>)}
                </dl>
                <Form contactoNuevo={this.contactoNuevo} />
            </div>
        );
    }
    contactoNuevo = (cumplex, nombrex) => {

        const persona = {
            id: this.state.contactos.length+1,
            cumple: cumplex.substr(8, 2) + "-" + cumplex.substr(5, 2) + "-" + cumplex.substr(0, 4),
            nombre: nombrex,
            regalo: false,
        }
        alert(cumplex + "-" + nombrex);
        this.setState({
            contactos: [...this.state.contactos, persona]
        });
    }
    eliminarContacto = (id) => {
        const resp = this.state.contactos.filter(contacto => contacto.id !== id);
        this.setState({ contactos: resp });
    }
    modificarContacto = (id) => {
        const resp = this.state.contactos.map(contacto => {
            if (contacto.id === id) {
                contacto.regalo = !contacto.regalo;
                console.log(id + "-" + contacto.id);
            }
            return contacto;
        });
        this.setState({contactos:resp})
    }
}

export default App;
